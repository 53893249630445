import React from "react";

import { NotFoundPage, getProperty } from "@zeus/shared";

import { useMeta } from "hooks";

const Template = () => {
  const meta = useMeta();

  const title = getProperty(meta, "error.title");
  const text = getProperty(meta, "error.content");
  const button = getProperty(meta, "back_home");

  return <NotFoundPage title={title} text={text} button={button} />;
};

export default Template;
